import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { LoadingSpinner } from "atoms";
import { AuthProvider } from "features/auth";
import { I18nProvider } from "./i18n";
import { QueryProvider } from "./query";
import { ThemeProvider } from "./theme";
import { WalletProvider } from "./wallet";

interface AppProvidersProps {
  children: React.ReactNode;
}

function AppProviders({ children }: AppProvidersProps) {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <WalletProvider>
          <AuthProvider>
            <QueryProvider>
              <I18nProvider>
                <ThemeProvider>
                  <React.Suspense fallback={<LoadingSpinner />}>
                    {children}
                  </React.Suspense>
                </ThemeProvider>
              </I18nProvider>
            </QueryProvider>
          </AuthProvider>
        </WalletProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export { AppProviders };
