import { useQuery, useMutation, useQueryClient } from "react-query";
import { useAuth } from "features/auth";
import { api } from "utils/api";
import type { NotificationResponse } from "../types";

const getNotifications = () =>
  api.get<NotificationResponse>(`/profile/notifications`, {
    requireAuth: true,
  });

/**
 * Custom hook to fetch list of all user's notifications
 */
const useNotifications = () => {
  const { user } = useAuth();
  return useQuery(["notifications"], getNotifications, {
    enabled: !!user,
    select: (resp) => resp.data,
  });
};

const markNotificationsAsRead = async (ids: string[]) => {
  const options = {
    body: JSON.stringify({ read: true }),
  };

  return Promise.all(
    ids.map((id) => api.put(`/profile/notifications/${id}`, options)),
  );
};

const useMarkNotificationsAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation(markNotificationsAsRead, {
    onSuccess: () => {
      queryClient.invalidateQueries(["notifications"]);
    },
  });
};

export { useNotifications, useMarkNotificationsAsRead };
