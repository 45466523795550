import * as React from "react";
import styled from "@emotion/styled";
import { Navigate } from "react-router-dom";
import {
  Alert,
  AllCaps,
  Box,
  Button,
  CharacterCounter,
  Label,
  FakeLabel,
  FieldError,
  FlexRow,
  Form,
  H2,
  Hr,
  IconCheckRounded,
  IconInstagram,
  IconTikTok,
  IconTwitter,
  IconWebsite,
  IconYouTube,
  Input,
  InputPre,
  Legend,
  LoadingEllipses,
  LoadingSpinner,
  MultiSelect,
  NoBreak,
  RequiredText,
  Select,
  Stack,
  TextArea,
  UnexpectedError,
  Link,
  IconOrb,
  IconFarcaster,
} from "atoms";
import { ImageUpload } from "features/images";
import { useAuth } from "features/auth";
import { paths } from "utils/paths";
import { countries } from "utils/countries";
import { fieldOptions, validators } from "utils/form";
import { LinksInput } from "features/misc";
import { useArtistForm } from "../api/useArtistForm";
import type { Artist } from "../types";
import { artistAttributes, artistMediums } from "../data";

const textFieldMaxLength = 100;
const textFieldRequirements = {
  ...fieldOptions.required,
  ...fieldOptions.maxLength(textFieldMaxLength),
};

const textAreaMaxLength = 2000;
const textAreaRequirements = {
  ...fieldOptions.required,
  ...fieldOptions.maxLength(textAreaMaxLength),
};

type ArtistFormProps = {
  artist?: Artist;
};

const mediumOptions = artistMediums.map((tag) => ({ value: tag }));
const artistOptions = artistAttributes.map((tag) => ({ value: tag }));

function ArtistForm({ artist }: ArtistFormProps) {
  const {
    roles: { showAdminFeatures },
  } = useAuth();

  const {
    control,
    formState: { errors, isDirty },
    isEdit,
    mutation: {
      isSuccess,
      error,
      isError,
      isLoading: isArtistUpdating,
      data: updatedArtist,
    },
    onSubmit,
    register,
    setFocus,
    watch,
  } = useArtistForm(artist);

  const isDuplicateId =
    error instanceof Error && error?.message.includes("ID Already Used");
  const showStatusField = artist?.status === "DRAFT" || showAdminFeatures;

  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const showSuccessDuration = 7;

  const submitLabel: string = isEdit ? "Update Profile" : "Submit Artist";

  React.useEffect(() => {
    if (isSuccess) {
      setShowSuccessMessage(true);

      const timeout = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 1000 * showSuccessDuration);

      return () => clearTimeout(timeout);
    }

    return noop;
  }, [isSuccess]);

  React.useEffect(() => {
    if (isDuplicateId) {
      setFocus("id");
    }
  }, [isDuplicateId, setFocus]);

  const description = watch("description");

  if (!artist && isSuccess) {
    return <Navigate to={`/artists/${updatedArtist?.id}`} />;
  }

  return (
    <SForm onSubmit={onSubmit}>
      <Box fullWidth>
        <Stack gap="md">
          <Stack gap="sm">
            <H2 size="lg">About</H2>
            {!isEdit && (
              <Label>
                Artist ID / SLUG
                <Input
                  {...register("id", {
                    ...textFieldRequirements,
                    validate: validators.validSlug,
                  })}
                  maxLength={textFieldMaxLength}
                  isInvalid={!!errors.id}
                  aria-invalid={!!errors.id}
                />
                {errors.id && <FieldError>{errors.id.message}</FieldError>}
                {isDuplicateId && (
                  <FieldError>
                    Sorry, that Artist ID already exists. Choose another and
                    resubmit.
                  </FieldError>
                )}
              </Label>
            )}

            <Label>
              Artist Name
              <RequiredText />
              <Input
                {...register("name", textFieldRequirements)}
                maxLength={textFieldMaxLength}
                isInvalid={!!errors.name}
                aria-invalid={!!errors.name}
              />
              {errors.name && <FieldError>{errors.name.message}</FieldError>}
            </Label>

            <Label>
              Pronouns
              <Input
                {...register(
                  "pronouns",
                  fieldOptions.maxLength(textFieldMaxLength),
                )}
              />
              {errors.pronouns && (
                <FieldError>{errors.pronouns.message}</FieldError>
              )}
            </Label>

            <Label>
              Country of Residence
              <Select {...register("country")}>
                <option value="">Not Specified</option>
                {countries.map((c) => (
                  <option key={c} value={c}>
                    {c}
                  </option>
                ))}
              </Select>
            </Label>

            <Label>
              City of Residence
              <Input
                {...register(
                  "city",
                  fieldOptions.maxLength(textFieldMaxLength),
                )}
              />
              {errors.city && <FieldError>{errors.city.message}</FieldError>}
            </Label>

            <Label>
              Bio
              <RequiredText />
              <TextArea
                {...register("description", textAreaRequirements)}
                maxLength={textAreaMaxLength}
                isInvalid={!!errors.description}
                aria-invalid={!!errors.description}
                style={{ minHeight: 200 }}
              />
              {errors.description && (
                <FieldError>{errors.description.message}</FieldError>
              )}
              <CharacterCounter
                max={textAreaMaxLength}
                value={description ?? ""}
              />
            </Label>
          </Stack>
          <Hr />
          <Stack gap="md">
            <H2 size="lg">Profile Media</H2>
            <div>
              <FakeLabel>
                Profile Image
                <RequiredText />
              </FakeLabel>

              <ImageUpload
                control={control}
                fieldName="heroImage"
                required
                src={artist?.heroImage}
                errorMessage={errors.heroImage?.url?.message}
                aspect="square"
                previewWidth={100}
                variant="circle"
              />
            </div>

            <div>
              <FakeLabel>
                Artist Card Image
                <RequiredText />
                <br />
                <small>
                  Square image on your Artist Profile card that comes up on the{" "}
                  <AllCaps>Hug</AllCaps> Homepage and Search.{" "}
                  <NoBreak>Recommended dimensions: 1200 x 1200</NoBreak>
                </small>
              </FakeLabel>

              <ImageUpload
                control={control}
                fieldName="cardImage"
                src={artist?.cardImage}
                errorMessage={errors.cardImage?.url?.message}
                aspect="square"
                previewWidth={300}
              />
            </div>

            <div>
              <FakeLabel>
                Banner Image
                <br />
                <small>
                  Rectangular image that appears in the header of your Artist
                  Profile. <NoBreak>Recommended dimensions: 1500 x 500</NoBreak>
                </small>
              </FakeLabel>

              <ImageUpload
                control={control}
                fieldName="bannerImage"
                src={artist?.bannerImage}
                errorMessage={errors.bannerImage?.url?.message}
                aspect="banner"
                previewWidth={400}
              />
            </div>
            <Label>
              YouTube Video
              <br />
              <small>
                To add a video to your Artist Profile, upload your video to
                YouTube and copy/paste the video ID found in the video&nbsp;URL{" "}
                <NoBreak>
                  (youtube.com/watch?v=<strong>aa3Afg3fzAQ</strong>)
                </NoBreak>
              </small>
              <Input
                {...register("youTubeVideo")}
                isInvalid={!!errors.youTubeVideo}
                aria-invalid={!!errors.youTubeVideo}
                placeholder="aa3Afg3fzAQ"
              />
              {errors.youTubeVideo && (
                <FieldError>{errors.youTubeVideo.message}</FieldError>
              )}
            </Label>
          </Stack>
          <Hr />
          <Stack gap="sm">
            <H2 size="lg">Links</H2>
            <Label>
              Personal Website
              <FlexRow gap="0">
                <WebsiteInputPre>
                  <FlexRow gap="10px">
                    <IconWebsite size="16px" />
                  </FlexRow>
                </WebsiteInputPre>
                <Input
                  {...register(`webSiteURL`, {
                    validate: validators.validURL,
                    // TODO: Custom URL validation
                  })}
                  autoCapitalize="off"
                  placeholder="your-website.com"
                />
              </FlexRow>
              {errors.webSiteURL && (
                <FieldError>{errors.webSiteURL?.message}</FieldError>
              )}
            </Label>
            <Label>
              Instagram Handle
              <FlexRow gap="0">
                <SInputPre>
                  <FlexRow gap="10px">
                    <IconInstagram size="16px" />
                    instagram.com/
                  </FlexRow>
                </SInputPre>
                <Input
                  {...register(`instagramHandle`, {
                    validate: validators.validSocialHandle,
                  })}
                  autoCapitalize="off"
                  placeholder="your_handle"
                />
              </FlexRow>
              {errors.instagramHandle && (
                <FieldError>{errors.instagramHandle?.message}</FieldError>
              )}
            </Label>
            <Label>
              X (Twitter) Handle
              <FlexRow gap="0">
                <SInputPre>
                  <FlexRow gap="10px">
                    <IconTwitter size="16px" />
                    twitter.com/
                  </FlexRow>
                </SInputPre>
                <Input
                  {...register(`twitterHandle`, {
                    validate: validators.validSocialHandle,
                  })}
                  autoCapitalize="off"
                  placeholder="your_handle"
                />
              </FlexRow>
              {errors.twitterHandle && (
                <FieldError>{errors.twitterHandle?.message}</FieldError>
              )}
            </Label>
            <Label>
              YouTube Channel Handle URL
              <FlexRow gap="0">
                <SInputPre>
                  <FlexRow gap="10px">
                    <IconYouTube size="16px" />
                    youtube.com/@
                  </FlexRow>
                </SInputPre>
                <Input
                  {...register(`youTubeHandle`, {
                    validate: validators.validSocialHandle,
                  })}
                  autoCapitalize="off"
                  placeholder="your_handle_url"
                />
              </FlexRow>
              {errors.youTubeHandle && (
                <FieldError>{errors.youTubeHandle?.message}</FieldError>
              )}
            </Label>
            <Label>
              TikTok Handle
              <FlexRow gap="0">
                <SInputPre>
                  <FlexRow gap="10px">
                    <IconTikTok size="16px" />
                    tiktok.com/@
                  </FlexRow>
                </SInputPre>
                <Input
                  {...register(`tikTokHandle`, {
                    validate: validators.validSocialHandle,
                  })}
                  autoCapitalize="off"
                  placeholder="your_handle"
                />
              </FlexRow>
              {errors.tikTokHandle && (
                <FieldError>{errors.tikTokHandle?.message}</FieldError>
              )}
            </Label>

            <Label>
              Lens Handle
              <FlexRow gap="0">
                <SInputPre>
                  <FlexRow gap="10px">
                    <IconOrb size="16px" />
                    hey.xyz/u/
                  </FlexRow>
                </SInputPre>
                <Input
                  {...register(`lensHandle`, {
                    validate: validators.validSocialHandle,
                  })}
                  autoCapitalize="off"
                  placeholder="your_handle"
                />
              </FlexRow>
              {errors.lensHandle && (
                <FieldError>{errors.lensHandle?.message}</FieldError>
              )}
            </Label>
            <Label>
              Farcaster Handle
              <FlexRow gap="0">
                <SInputPre>
                  <FlexRow gap="10px">
                    <IconFarcaster size="16px" />
                    warpcast.com/
                  </FlexRow>
                </SInputPre>
                <Input
                  {...register(`farCasterHandle`, {
                    validate: validators.validSocialHandle,
                  })}
                  autoCapitalize="off"
                  placeholder="your_handle"
                />
              </FlexRow>
              {errors.farCasterHandle && (
                <FieldError>{errors.farCasterHandle?.message}</FieldError>
              )}
            </Label>
          </Stack>

          <LinksInput
            control={control}
            errors={errors}
            register={register}
            name="links"
            legend={
              <Stack gap="xxs" style={{ marginBottom: 8, marginTop: 10 }}>
                <Legend size="sm">
                  Other Links
                  <br />
                  <small>These links appear only in your About tab.</small>
                </Legend>
              </Stack>
            }
          />
          <Hr />
          <Stack gap="sm">
            <H2 size="lg">Tags</H2>
            <Stack>
              <MultiSelect
                options={mediumOptions}
                control={control}
                label="Choose tags to describe your art or creative mediums."
                name="medium"
                error={errors.medium?.message}
                isStatic
              />
            </Stack>
            <Stack>
              <MultiSelect
                options={artistOptions}
                control={control}
                label="Choose tags that align with your identity."
                name="founderAttributes"
                error={errors.founderAttributes?.message}
                isStatic
              />
            </Stack>
          </Stack>

          {showAdminFeatures && (
            <>
              <Hr />
              <Stack gap="sm">
                <H2 size="lg">🔒 Admin Fields</H2>
                {showStatusField && (
                  <Label>
                    Status
                    <RequiredText />
                    <Select
                      {...register("status", { ...fieldOptions.required })}
                    >
                      <option value="PUBLISHED">Published</option>
                      <option value="DRAFT">Draft</option>
                      {showAdminFeatures && (
                        <option value="ARCHIVED">Archived</option>
                      )}
                    </Select>
                  </Label>
                )}
              </Stack>
            </>
          )}
        </Stack>
      </Box>

      <FormFooter>
        {showSuccessMessage && !isDirty && (
          <Alert
            duration={showSuccessDuration}
            aria-live="polite"
            type="success"
          >
            {/* Make disappear after a few seconds */}
            <IconCheckRounded />
            &nbsp;&nbsp;Your changes have been saved
          </Alert>
        )}

        {isError && <UnexpectedError error={error} />}

        <FlexRow
          justifyContent="center"
          flexWrap="nowrap"
          style={{ width: "100%" }}
        >
          {isDirty && (
            <Button
              variant="secondary"
              size="sm"
              style={{ margin: 0 }}
              onClick={() => window.location.reload()}
            >
              Clear Changes
            </Button>
          )}

          {artist && (
            <Link variant="secondary" to={paths.artistProfile(artist.id)}>
              View Profile
            </Link>
          )}

          <Button
            type="submit"
            variant="primary"
            size="sm"
            arrow={isEdit ? undefined : "r"}
            disabled={isArtistUpdating}
          >
            {isArtistUpdating ? (
              <>
                Updating
                <LoadingEllipses />
              </>
            ) : (
              <>{submitLabel}</>
            )}
          </Button>
        </FlexRow>
      </FormFooter>
      {isArtistUpdating && <LoadingSpinner text="Saving" />}
    </SForm>
  );
}

const SForm = styled(Form)({
  gap: "3rem",
  gridArea: "b",
  maxWidth: "750px",
  margin: "0 auto",
});

const FormFooter = styled.footer(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
  backgroundColor: theme.colors.bg,
  position: "sticky",
  bottom: 0,
  background: theme.colors.bg,
  padding: `1em 0 calc(1em + env(safe-area-inset-bottom))`,
  zIndex: 9,
  "&>*": {
    position: "relative",
    zIndex: 8,
  },
  "&::before, &::after": {
    backgroundColor: theme.colors.bg,
    content: "''",
    position: "absolute",
    top: 0,
    left: "50%",
    width: "100vw",
    bottom: 0,
    transform: "translateX(-50%)",
    zIndex: 7,
  },
  "&::before": {
    bottom: "50%",
    boxShadow: theme.boxShadow.dark,
    zIndex: 6,
  },
}));

const SInputPre = styled(InputPre)(({ theme }) => ({
  minWidth: 150,
  [theme.breakpoints.maxXs]: {
    display: "flex",
    minWidth: 0,
    paddingInline: ".5rem",
    svg: {
      display: "none",
    },
  },
}));

const WebsiteInputPre = styled(InputPre)(({ theme }) => ({
  [theme.breakpoints.maxXs]: {
    display: "flex",
    paddingInline: ".5rem",
  },
}));

export { ArtistForm };
