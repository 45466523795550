import * as React from "react";
import { Controller } from "react-hook-form";
import styled from "@emotion/styled";
import {
  AllCaps,
  Box,
  Button,
  Checkbox,
  Container,
  FieldError,
  Fieldset,
  FlexRow,
  Form,
  H1,
  H2,
  H3,
  IconGlobeAlt,
  IconInstagram,
  IconTwitter,
  Input,
  InputPre,
  Label,
  Legend,
  Link,
  List,
  RequiredText,
  Select,
  Stack,
  TagCheckbox,
  Text,
  TextArea,
  UnexpectedError,
} from "atoms";
import { constants } from "config";
import { useAuth } from "features/auth";
import { GenericImage, ImageUpload } from "features/images";
import { countries } from "utils/countries";
import { fieldOptions, validators } from "utils/form";
import { scrollToTop } from "utils/scroll";
import successImage from "assets/images/success-image.jpg";
import { useArtistApplicationForm } from "../api/useArtistApplicationForm";
import { artistAttributes, artistMediums } from "../data";
import type { ArtistApplication } from "../types";

const textFieldMaxLength = 100;
const textFieldRequirements = {
  ...fieldOptions.required,
  ...fieldOptions.maxLength(textFieldMaxLength),
};

const textAreaMaxLength = 2000;
const textAreaRequirements = {
  ...fieldOptions.required,
  ...fieldOptions.maxLength(textAreaMaxLength),
};

type ArtistApplicationFormProps = {
  application?: ArtistApplication;
  applicationBypassId?: string;
  collectiveName?: string;
  referralId?: string;
  onSuccess?: VoidFunction;
};

const linkInputs = [
  [
    "Personal Website",
    "website",
    "https://your-website.com",
    <IconGlobeAlt aria-hidden />,
  ],
  [
    "Instagram URL",
    "instagram",
    "https://instagram.com/your-username",
    <IconInstagram aria-hidden />,
  ],
  [
    "Twitter URL",
    "twitter",
    "https://twitter.com/your-username",
    <IconTwitter aria-hidden />,
  ],
] as const;

function ArtistApplicationForm({
  application,
  applicationBypassId,
  collectiveName,
  referralId,
  onSuccess = noop,
}: ArtistApplicationFormProps) {
  const { user } = useAuth();
  const signedInWithEmail = !!user?.email;

  const {
    control,
    formState: { errors },
    mutation: { isLoading, isSuccess, error: mutationError },
    onSubmit,
    register,
    isEdit,
    setValue,
    watch,
  } = useArtistApplicationForm({
    application,
    applicationBypassId,
    referralId,
  });

  React.useEffect(() => {
    if (isSuccess) {
      scrollToTop();
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  if (isSuccess) {
    return isEdit ? (
      <UpdateArtistApplicationSuccess applicationId={application?.id ?? ""} />
    ) : (
      <CreateArtistApplicationSuccess collectiveName={collectiveName} />
    );
  }

  const hasAtLeastOneLink = watch("links")?.some(({ url }) => !!url);

  return (
    <FormWrapper>
      <SForm onSubmit={onSubmit}>
        <Section title="About Me">
          <Label>
            Email Address
            <RequiredText />
            <Input
              {...register("email", textFieldRequirements)}
              maxLength={textFieldMaxLength}
              isInvalid={!!errors.email}
              aria-invalid={!!errors.email}
              disabled={signedInWithEmail}
            />
            {signedInWithEmail && (
              <Text size="xxs" as="small">
                To change your email address, go to your{" "}
                <Link to="/profile/edit#email">account settings</Link>.
              </Text>
            )}
            {errors.email && <FieldError>{errors.email.message}</FieldError>}
          </Label>

          <Label>
            Artist Name
            <RequiredText />
            <Input
              {...register("artistName", textFieldRequirements)}
              maxLength={textFieldMaxLength}
              isInvalid={!!errors.artistName}
              aria-invalid={!!errors.artistName}
            />
            {errors.artistName && (
              <FieldError>{errors.artistName.message}</FieldError>
            )}
          </Label>

          <Label>
            Pronouns
            <Input
              {...register(
                "pronouns",
                fieldOptions.maxLength(textFieldMaxLength),
              )}
            />
            {errors.pronouns && (
              <FieldError>{errors.pronouns.message}</FieldError>
            )}
          </Label>

          <Label>
            Country of Residence
            <RequiredText />
            <Select
              {...register("location", fieldOptions.required)}
              isInvalid={!!errors.location}
              aria-invalid={!!errors.location}
            >
              <option value="">Choose country</option>
              {countries.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </Select>
            {errors.location && (
              <FieldError>{errors.location.message}</FieldError>
            )}
          </Label>

          <Fieldset>
            <Controller
              control={control}
              name="privateLocation"
              render={({ field: { value, ...field } }) => (
                <Checkbox
                  {...field}
                  onChange={(e) =>
                    setValue("privateLocation", !e.target.checked)
                  }
                  value="public"
                  checked={value === false}
                  label={<strong>Show my location on my artist profile</strong>}
                />
              )}
            />
          </Fieldset>
        </Section>

        <Section title="Artist Profile">
          <Label>
            Enter your preferred <AllCaps>Hug</AllCaps> Artist Profile ID
            <RequiredText />
            <br />
            <InputSlugDescription size="xxs">
              thehug.xyz/artists/<strong>your-artist-id</strong>
            </InputSlugDescription>
            <FlexRow gap="0" itemsFlex="1">
              <InputPre>thehug.xyz/artists/</InputPre>
              <Input
                placeholder="your-artist-id"
                {...register("preferredSlug", {
                  ...fieldOptions.required,
                })}
              />
              <div style={{ flex: "0 0 auto" }}>
                {errors.preferredSlug && (
                  <FieldError>{errors.preferredSlug?.message}</FieldError>
                )}
              </div>
            </FlexRow>
          </Label>

          <ImageUpload
            label={
              <>
                Profile Picture
                <RequiredText />
              </>
            }
            control={control}
            fieldName="profileImage"
            required
            src={application?.profileImage}
            errorMessage={errors.profileImage?.url?.message}
            aspect="square"
            variant="circle"
          />

          <Label>
            Bio
            <RequiredText />
            <br />
            <small>
              Not sure what to say? Read our article{" "}
              <Link
                href="https://creatorroyalties.beehiiv.com/p/015-artist-bio"
                target="_blank"
              >
                <em>How to Write the Perfect Artist Bio</em>
              </Link>
              .
            </small>
            <TextArea
              {...register("description", textAreaRequirements)}
              maxLength={textAreaMaxLength}
              isInvalid={!!errors.description}
              aria-invalid={!!errors.description}
            />
            {errors.description && (
              <FieldError>{errors.description.message}</FieldError>
            )}
          </Label>

          <Fieldset style={{ maxWidth: "100%", width: "100%" }}>
            <Legend size="sm">
              Artist Medium <small>(select all that apply)</small>
            </Legend>
            <TagList>
              {artistMediums.map((medium) => (
                <STagCheckbox
                  key={medium}
                  label={medium}
                  value={medium}
                  variant="medium"
                  {...register("artMedium")}
                />
              ))}
            </TagList>
          </Fieldset>

          <Fieldset>
            <Legend size="sm">
              Artist Tags <small>(select all that apply)</small>
            </Legend>
            <TagList>
              {artistAttributes.map((attribute) => (
                <STagCheckbox
                  key={attribute}
                  label={attribute}
                  value={attribute}
                  variant="founderAttribute"
                  {...register("artistTags")}
                />
              ))}
            </TagList>
          </Fieldset>
        </Section>

        <Section title="Where to Find Me">
          {linkInputs.map(([label, type, placeholder, icon], index) => (
            <Label key={`${type}-${label}`}>
              {label}
              <FlexRow gap="0">
                <InputPre>{icon}</InputPre>
                <Input
                  {...register(`links.${index}.url`, {
                    validate: {
                      url: (val: string) => !val || validators.validURL(val),
                      atLeastOne: () =>
                        hasAtLeastOneLink || "Please enter at least one link",
                    },
                  })}
                  placeholder={placeholder}
                  autoCapitalize="off"
                />
              </FlexRow>
              <Input
                type="hidden"
                {...register(`links.${index}.type`)}
                value={type}
              />
              {errors.links?.[index]?.url && (
                <FieldError>{errors.links?.[index]?.url?.message}</FieldError>
              )}
            </Label>
          ))}
        </Section>

        <Section title="My Work">
          <Text size="xxxs">
            Please upload an image of your best work that you would like to
            showcase. Once you are invited to join <AllCaps>Hug</AllCaps>, you
            will be able to add additional artworks and collections to your
            Artist Profile.
          </Text>

          <ImageUpload
            label={
              <>
                Featured Artwork
                <RequiredText />
              </>
            }
            control={control}
            fieldName="artistCollections.0.collectionImage"
            required
            src={application?.artistCollections?.[0].collectionImage}
            errorMessage={
              errors.artistCollections?.[0]?.collectionImage?.url?.message
            }
            aspect="square"
          />
          <Label>
            Title of Artwork
            <RequiredText />
            <Input
              type="text"
              {...register("artistCollections.0.collectionName", {
                ...fieldOptions.required,
              })}
            />
            {errors.artistCollections?.[0]?.collectionName && (
              <FieldError>
                {errors.artistCollections?.[0]?.collectionName.message}
              </FieldError>
            )}
          </Label>

          <Label>
            Link to Artwork <RequiredText />
            <small>
              Share a link for others to see or purchase this artwork.
            </small>
            <FlexRow gap="0" itemsFlex="1">
              <InputPre>
                <IconGlobeAlt />
              </InputPre>
              <Input
                {...register("artistCollections.0.collectionLinks.0.url", {
                  ...fieldOptions.required,
                  validate: validators.validURL,
                })}
                type="url"
              />
              <Input
                type="hidden"
                {...register("artistCollections.0.collectionLinks.0.type")}
                value="website"
              />
              {errors.artistCollections?.[0]?.collectionLinks?.[0]?.url && (
                <FieldError style={{ flex: "0 0 auto" }}>
                  {
                    errors.artistCollections?.[0]?.collectionLinks?.[0]?.url
                      .message
                  }
                </FieldError>
              )}
            </FlexRow>
          </Label>
        </Section>

        <Section title="Give Out a Hug">
          <Label>
            How did you hear about <AllCaps>Hug</AllCaps>?
            <br />
            <small>
              If someone told you about us, let us know their name so we can
              thank them!
            </small>
            <Input {...register("artistHUGDiscovery")} />
            {errors.artistHUGDiscovery && (
              <FieldError>{errors.artistHUGDiscovery.message}</FieldError>
            )}
          </Label>
        </Section>

        <Section>
          {!isEdit ? (
            <FormFooter>
              <Stack gap="sm">
                <Checkbox
                  label={
                    <>
                      Subscribe me to Creator Royalties, our weekly educational
                      newsletter covering the latest news, tools, and
                      opportunities for&nbsp;artists.
                    </>
                  }
                  {...register("subscribeCreatorRoyalties")}
                />

                <Checkbox
                  label={
                    <>
                      I agree to the <AllCaps>Hug</AllCaps>&apos;s{" "}
                      <Link to="/privacy-policy">Privacy&nbsp;Policy</Link> and{" "}
                      <Link to="/terms-of-service" target="_blank">
                        Terms&nbsp;of&nbsp;Service
                      </Link>
                      .
                      <RequiredText />
                      {errors.agreeToTerms && (
                        <FieldError style={{ marginTop: 3 }}>
                          {errors.agreeToTerms.message}
                        </FieldError>
                      )}
                    </>
                  }
                  {...register("agreeToTerms", {
                    required: "You must agree to submit this application",
                  })}
                />

                <Button
                  type="submit"
                  variant="primary"
                  disabled={isLoading}
                  size="sm"
                  arrow="r"
                >
                  Submit Application
                </Button>
              </Stack>
            </FormFooter>
          ) : (
            <div>
              <Button type="submit" variant="primary" disabled={isLoading}>
                Update Application
              </Button>
            </div>
          )}

          {!!mutationError && <UnexpectedError error={mutationError} />}
        </Section>
      </SForm>
    </FormWrapper>
  );
}

function CreateArtistApplicationSuccess({
  collectiveName,
}: {
  collectiveName?: string;
}) {
  return (
    <Box>
      <H2 textAlign="center">
        Thank you for applying&nbsp;to&nbsp;<AllCaps>Hug</AllCaps>!
      </H2>

      {collectiveName ? (
        <Text bold>
          We are confirming your membership with {collectiveName}{" "}
          representatives. Hang tight&mdash;we can&apos;t wait to explore your
          work and learn more about you.
        </Text>
      ) : (
        <Text bold>
          Your application is officially pending review. We can&apos;t wait to
          explore your work and learn more about&nbsp;you!
        </Text>
      )}

      <Text>
        All applications undergo review for quality assurance by our community
        of curators prior to publication. You’ll receive an email once a
        decision has been made. Until then, you can track the status of your
        application through <Link to="/profile">Your Account</Link>.
      </Text>

      <FlexRow gap="1em" itemsFlex="1 0 175px" style={{ margin: "1.5em 0" }}>
        <SGenericImage
          src={successImage}
          width="232"
          height="300"
          sizes="240w"
          aria-hidden
        />
        <Stack>
          <H3 size="md">Explore Hug</H3>
          <Text>
            See what other artists are up to on <AllCaps>Hug</AllCaps> while you
            wait.
          </Text>
          <Link to="/search" variant="primary" size="xxs">
            Explore Artists
          </Link>
        </Stack>
      </FlexRow>

      <Text>
        Don&apos;t forget you can also explore our{" "}
        <Link to="/resources">free resource library</Link> for artists and art
        lovers.
      </Text>
      <Text>
        Keep in touch with us on{" "}
        <Link target="_blank" href={constants.twitterURL}>
          Twitter
        </Link>{" "}
        and{" "}
        <Link target="_blank" href={constants.instagramURL}>
          Instagram
        </Link>
        .
      </Text>
    </Box>
  );
}

const SGenericImage = styled(GenericImage)({
  margin: "0 auto",
  maxWidth: 240,
  objectFit: "none",
  img: { aspectRatio: "232 / 300", objectFit: "contain" },
});

function UpdateArtistApplicationSuccess({
  applicationId,
}: {
  applicationId: string;
}) {
  return (
    <Container width="xs">
      <H1>Application Updated!</H1>

      <Link to={`/admin/applications/${applicationId}`} variant="primary">
        View Application
      </Link>
    </Container>
  );
}

function Section({
  title,
  children,
  ...props
}: {
  title?: string;
  children: React.ReactNode;
}) {
  return (
    <SBox fullWidth {...props}>
      <Stack gap="sm">
        {title && <SectionTitle>{title}</SectionTitle>}
        {children}
      </Stack>
    </SBox>
  );
}

const sectionPadding = "var(--form-padding)";

const SBox = styled(Box)(({ theme }) => ({
  padding: sectionPadding,
  marginLeft: "auto",
  marginRight: "auto",
  flex: "auto",
  scrollSnapAlign: "start",
  overflow: "visible",
  scrollMargin: 80,
  width: "100%",
  [theme.breakpoints.maxTablet]: {
    boxShadow: theme.boxShadow.light,
    paddingTop: "2rem",
    paddingBottom: "2rem",
  },
  "@media (min-width: 640px)": {
    width: `calc(640px - (${sectionPadding} * 2))`,
    maxWidth: `calc(640px - (${sectionPadding} * 2))`,
    minWidth: `calc(640px - (${sectionPadding} * 2))`,
  },
})).withComponent("section");

const FormWrapper = styled.div(({ theme }) => ({
  position: "relative",
  [theme.breakpoints.desktop]: {
    display: "flex",
    justifyContent: "center",
    gap: sectionPadding,
    flex: "0 0 auto",
  },
}));

const SForm = styled(Form)({
  gap: 10,
});

const FormFooter = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  label: {
    fontSize: 14,
  },
});

const SectionTitle = styled(H2)({});
SectionTitle.defaultProps = {
  size: "md",
};

const InputSlugDescription = styled(Text)(({ theme }) => ({
  margin: 0,
  [theme.breakpoints.xs]: { display: "none" },
  "&&": {
    fontWeight: "normal",
  },
}));

const TagList = styled(List)({
  display: "flex",
  flexWrap: "wrap",
  gap: 10,
  whiteSpace: "nowrap",
  paddingLeft: 0,
  margin: ".5em 0",
  li: {
    display: "flex",
    alignItems: "center",
  },
});

const STagCheckbox = styled(TagCheckbox)({
  margin: 0,
});

export { ArtistApplicationForm };
