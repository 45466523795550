import { useInfiniteQuery, useQuery } from "react-query";
import { ApiError, api } from "utils/api";
import { searchKeys } from "./queryKeys";
import type { SearchRequest, SearchResponse } from "../types";

// SORT - Pass an array of field names to sort. Add "desc" after to sort descending
// sort: ['mintStatus', 'mintDate desc']

const postSearch = (request?: SearchRequest) => {
  if (!request) {
    return Promise.resolve({ searchAfter: "", data: [], totalResults: 0 });
  }
  return api.post<SearchResponse>("/projects", {
    body: JSON.stringify({
      perPage: 4,
      ...request,
      type: "ARTIST",
    }),
  });
};

/**
 * Custom hook to search for Reviewables with infinite pagination and manage state
 */
const useInfiniteSearch = (params: SearchRequest) =>
  useInfiniteQuery<SearchResponse, ApiError>(
    searchKeys.infinite(params),
    ({ pageParam }) =>
      postSearch({
        searchAfter: pageParam,
        ...params,
      }),
    {
      getNextPageParam: (lastPage: SearchResponse) => lastPage.searchAfter,
    },
  );

/**
 * Custom hook to search for Reviewable by name incrementally and manage state
 *
 * NOTE: If `name` is user input, make sure to debounce value with `useDebouncer()` util hook
 */
const useIncrementalSearch = (name: string, perPage = 4) =>
  useQuery<SearchResponse, ApiError, SearchResponse["data"]>(
    searchKeys.incremental({ name, perPage }),
    () =>
      postSearch({
        incremental: true,
        name,
        perPage,
      }),
    {
      enabled: !!name,
      select: (res) => res.data,
      keepPreviousData: true,
    },
  );

const useArtistsByIds = ({ ids }: { ids: string[] }) =>
  useQuery(
    searchKeys.default({ ids }),
    () => postSearch({ ids, perPage: 999 }),
    {
      enabled: ids.length > 0,
      select: ({ data }) => data,
    },
  );

export { useIncrementalSearch, useInfiniteSearch, useArtistsByIds };
